<template>
	<div class="homeFooter-comp-wrap">
		<div class="homeFooter-wrap-inside">
			<div class="content-fixed-w">
				<div class="homeFooter-content-container container-fiuled">
					<div class="content-row-wrap row m-0 p-0">
						<div class="logo col-lg-2 col-12 ml-0 pl-0"></div>
						<div class="sub-row1 col-lg-3 col-12 container row m-0 p-0 mt-4 mt-lg-0">
							<!-- <div class="col-4 m-0 p-0">
								<div class="subrow-title">产品</div>
								<div class="subrow-text">Low Code</div>
								<div class="subrow-text">No Code</div>
							</div> -->
							<div class="col-8 m-0 p-0">
								<div class="subrow-title">解决方案 </div>
								<div class="subrow-text">
									<a :href="`${domain}/option/tone`">低代码解决方案</a> 
								</div>
								<div class="subrow-text">
									<a :href="`${domain}/option/datacenter`">数据中台解决方案</a> 
								</div>
								<div class="subrow-text">
									<a :href="`${domain}/option/ai`">人工智能解决方案</a> 
								</div>
							</div>
						</div>
						<div class="sub-row2 col-lg-4 col-12 container row m-0 p-0 mt-5 mt-lg-0">
							<div class="col-12 m-0 p-0">
								<div class="subrow-title">联系我们</div>
								<div class="subrow-text">电话：（010）60779274</div>
								<div class="subrow-text">
									地址：北京市昌平区北清路TBD云集中心4号楼B座5层
								</div>
								<div class="subrow-text">
									邮箱：
									marketing@linglongtop.com
								</div>
							</div>
						</div>
						<div class="col-lg-3 col-12 m-0 p-0 mt-lg-0 mt-3 qrCode"></div>
					</div>
				</div>

				<div class="copyright-info">
					<div>版权所有© 北京玲珑塔科技有限公司 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2021034807号</a> </div>
					<p class="ali">本网站由阿里云提供云计算及安全服务本网站支持</p>
					<p class="arg-support">IPv6 Powered by CloudDream</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
const domain = window.location.origin + '#';
</script>

<style lang="scss" scoped>
// 默认风格定制
.homeFooter-comp-wrap{
	--footerBg: '#FFFFFF';
	--textColor1: #22222b;
	--textColor2: rgba(34,34,43,0.4);
	--logoImg: #ffffff url('~@/assets/imgs/footer-logo-black.png') no-repeat 0px 0px;
	--qrCodeImg: #ffffff url('~@/assets/imgs/qrCode.png') no-repeat 0px 0px;
	--goTopArr: url('~@/assets/imgs/icon/arr-top-black.png') no-repeat center center;
}

// 样式
.homeFooter-comp-wrap {
	width: 100%;
	text-align: left;
	color: var(--textColor1);
	background-color: var(--footerBg);

	.homeFooter-wrap-inside {
		padding: 3.44rem 2rem 2rem;
		border-top: 1px solid var(--textColor1);
		position: relative;
	}
	.homeFooter-content-container {
		padding-top: 3rem;
	}
	.content-row-wrap {
		a{
			color: var(--textColor1);
			&:hover{
				text-decoration: underline;
				text-underline-offset: 2px;
			}
		}
	}
	.logo {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: -3rem;
			width: 7.5rem;
			height: 2.5rem;
			background: var(--logoImg);
			background-size: cover;
		}
	}
	.sub-row1 {
	}
	.sub-row2 {
	}
	.subrow-title {
		font-size: 1rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 600;
		// color: #22222b;
		line-height: 1;
        margin-bottom: 1rem;
	}
	.subrow-text {
        margin-top: 0.75rem;
		font-size: 1rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		line-height: 1;
	}
	.qrCode {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 4.5rem;
			height: 4.5rem;
			background: var(--qrCodeImg);
			background-size: cover;
		}
	}



    .copyright-info{
        padding-top: 7.5rem;
        font-size: 0.75rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: var(--textColor2);
        line-height: 1;
		a{
			color: var(--textColor2);
			&:hover{
				text-decoration: underline;
				text-underline-offset: 2px;
			}
		}
        p{
            font-size: 0.75rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: var(--textColor2);
            line-height: 1;

        }
        .ali{
            margin-top: 0.5rem;
            margin-right: 1rem;
        }
        .pc &{
            .arg-support,.ali{
                display: inline-block;
            }
        }
        .mobile &{
            .arg-support{
                display: block;
                margin-top: 0.3rem;
            }
        }
    }
}

.mobile #app, .app-small #app{
	.homeFooter-comp-wrap{
		.homeFooter-wrap-inside{
			padding: 3.94rem 1rem 1rem;
		}
		.homeFooter-content-container{
			padding-top: 0;
		}
		.logo{
			min-height: 1.94rem;
			&::before{
				top: 0;
				width: 6.85rem;
				height: 1.94rem;
			}
		}
		.subrow-title{
			font-size: 0.88rem;
		}
		.subrow-text{
			margin-top: 1rem;
			font-size: 0.88rem;
		}
		.qrCode{
			height: 4.5rem;
		}
		.copyright-info{
			font-size: 0.63rem;
			padding-top: 3.5rem;
			p{
				font-size: 0.63rem;
			}
		}
	}
}



</style>
