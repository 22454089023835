<template>
    <Layout>
		<router-view />
	</Layout>
</template>
<script>
import { defineComponent } from "vue";
import Layout from '@/components/layout'
import { onMounted } from "vue";
import { _isMobile, screenMediaQuery } from '@/hooks/useCommon.js'
import WOW from "wow.js";
export default defineComponent({
		components: {Layout},
		setup() {
			screenMediaQuery();
			onMounted(() => {
				new WOW({
					// boxClass: 'wow', // 需要执行动画的元素的 class; 【String类型】默认值：‘wow’；
					// animateClass: 'animated', // animation.css 动画的 class; 【String类型】默认值：‘animated’；
					// offset: 0, // 距离可视区域多少开始执行动画；【整数Number类型】默认值：0；
					// mobile: true, // 是否在移动设备上执行动画；【Boolean类型】默认值: true;
					// live: true // 异步加载的内容是否有效；【Boolean类型】默认值: true;
				}).init();
				/**
				 * 	1、data-wow-duration：更改动画持续时间
					2、data-wow-delay：动画开始前的延迟
					3、data-wow-iteration：动画的次数重复（无数次：infinite）
					4、data-wow-offset：开始动画的距离
				 */

				setTimeout(() => {
					document.dispatchEvent(new Event('render-event'))
				}, 10000)
			})
		},
		metaInfo: {
			//改变当前路由的title
			title: "北京玲珑塔科技有限公司",
			//改变当前路由的link
			meta: [
				{name: 'viewport', content: 'user-scalable=0,width=device-width, initial-scale=1.0'},
	
				{
					name: "keywords",
					content: "IT 技术咨询、应用开发、产品工程，客户化软件开发、维护与测试，以及业务流程服务，技术人员输出服务",
				},
				{
					name: "description",
					content: "北京玲珑塔科技有限公司（Beijing LinglongTopTechnology Co., Ltd.）成立于 2019 年，是专业软件与信息技术服务供应商。玲珑塔将客户的实际需求放在首位，凭借技术专长、领域知识、便捷的交付体系、专业的项目管理，为客户提供技术创新驱动的价值型 IT 服务与解决方案。",
				},
			],
			link: [
				{
					rel: "北京玲珑塔科技有限公司",
					href: "http://linglongtop.com",
				},
			],
		},
	})
</script>
<style lang="scss">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;

	overscroll-behavior: contain;
	
	[class$='_page']{
		text-align: justify;
		font-family: PingFangSC-Regular;
	}

}


</style>
